@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "../../util/styles/Variables.scss";
body {
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
}

header {
  background-color: #cccccc;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.thumb {
  background-color: #cccccc;
  .image-gallery-svg {
    stroke {
      color: black;
    }
    color: black;
  }
  .image-gallery-icon {
    filter: none;
  }

  img {
    max-width: 70%;
    height: auto;
    display: block;
    margin: auto;
  }
}

.sticky-bar {
  background-color: #242424;
  padding: 20px;
  position: sticky;
  top: 0;

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .col {
      display: block;

      &.right {
        a {
          background: #383838;
          border-radius: 9px;
          align-items: stretch;
          display: flex;
          text-decoration: none;
          transition: 0.3s all ease;

          svg {
            transition: 0.3s all ease;
          }

          &:hover {
            filter: brightness(1.2);

            svg {
              transform: scaleX(-1);
            }
          }
        }

        .actions-wrapper {
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 7px 10px;
          border-right: 1px solid #161616;

          span {
            color: white;
            font-weight: 500;
            text-decoration: none;
            font-size: 0.8rem;
          }

          .price-box {
            display: flex;
            gap: 5px;

            .special-price {
              color: #c89e78;
              font-size: 0.8rem;
            }

            .old-price {
              color: #707070;
              text-decoration: line-through;
              font-size: 0.8rem;
            }
          }
        }

        .cart-icon {
          padding: 10px 12px 10px 10px;
          border-left: 1px solid #606060;
        }
      }
    }
  }
}

.information {
  padding: 60px 20px;

  h1 {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1;
    margin: 0 0 10px 0;
  }

  p {
    line-height: 2;
    margin: 0;
    font-size: 0.8rem;
  }

  .label {
    color: grey;
    display: block;
    font-weight: 600;
    font-size: 1.2rem;
  }

  .value {
    font-size: 0.8rem;
    display: block;
  }
}

.sku,
.stock,
.variations {
  margin-top: 40px;
}

.stock-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  visibility: hidden;
  transition: 0.3s all ease;
  z-index: 9999;
}

div#stock {
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  background: #1e1e1e;
  left: 0;
  width: 100%;
  padding: 0;
  transition: 0.3s all ease;
  transform: translateY(100%);
  border-radius: 15px 15px 0 0;
  z-index: 9999;

  #close-stock-btn {
    background: rgba(30, 30, 30, 0.4);
    color: rgba(0, 0, 0, 1);
    position: absolute;
    right: 50%;
    transform: translate(50%, -40px);
    border: none;
    padding: 5px;
    border-radius: 50px;
    display: flex;
    cursor: pointer;
    svg {
      height: 20px;
      width: 20px;
      color: "white";
      background-color: rgba(30, 30, 30, 1);
      border-radius: 50px;
    }
  }
}

body.animateme {
  .stock-overlay {
    visibility: visible;
    background-color: rgba(200, 158, 120, 0.7);
  }

  div#stock {
    transform: translateY(0);
  }
}

.stock-wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: stretch;
  gap: 0;
  margin-top: 0;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  a {
    text-decoration: none;
    color: #f2f2f2;
  }
  .stock-design {
    width: 50%;
    background: transparent;
    padding: 40px 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: stretch;

    &:first-child {
      border-right: 1px solid #050505;
    }
    &:last-child {
      border-left: 1px solid #2a2a2a;
    }
    box-sizing: border-box;

    svg {
      margin-top: 0;
      margin-bottom: 0;
      width: 38px;
      transition: 0.3s all ease;
      stroke: #717171;
    }

    strong {
      display: block !important;
      font-size: 0.9rem;
      border-bottom: 0;
      padding-bottom: 0;
      color: #f2f2f2;
      transition: 0.3s all ease;
      text-align: center;
    }

    span {
      padding: 0;
      display: block;
      border-top: 0;
      transition: 0.3s all ease;
      font-size: 0.7rem;
      font-weight: normal;
      margin-top: 5px;
      color: #fff;
    }
  }
}

.animateme {
  #stock {
    svg {
      stroke: #fff !important;
      animation-name: bounceIn;
      animation-duration: 1s;
    }

    .stock-design {
      strong {
        color: #fff;
      }

      span {
        color: #c89e78;
      }
    }
  }
}

.variations {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    gap: 5px;
    margin-top: 15px;
    li {
      width: calc((100% / 8) - 5px);
      display: inline-block;
      cursor: pointer;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #242424;
        color: white;
        text-decoration: none;
        font-size: 14px;
        padding: 5px;
        height: auto;
        aspect-ratio: 1/1;

        &.active {
          background-color: black;
          border: 1px solid #c89e78;
        }
      }
    }
  }
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 60px;
}

//
.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@media only screen and (min-width: 1023px) {
  .thumb {
    background-color: #cccccc;

    img {
      width: 400px !important;
      margin: 0 auto;
    }
  }

  .sticky-bar {
    .wrapper {
      width: 600px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .information {
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
  }
}
