@import "../../util/styles/Variables.scss";

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: rgb(33, 33, 33);

  &-title {
    text-align: center;
    word-wrap: break-word;
    margin-bottom: 40px;
    margin-top: 40px;
  }
  &-items {
    max-width: 350px;
    padding: 20px;
    text-align: center;
    img {
      width: 100%;
      height: auto;
    }

    .generate {
      width: 100%;
      .container-status {
      }
      &-input {
        width: 100%;
        min-height: 60px;
        padding: 10px;
        background: rgba(0, 0, 0, 1);
        color: rgba(255, 255, 255, 1);
        transition: 0.3s all ease;
        border-color: rgba(0, 0, 0, 1);
        border-width: 0;
        font-size: 17px;
        letter-spacing: 1px;
        &:focus-visible {
          outline: none;
          border-width: 0 0 2px 0 !important;
          border-color: rgba(200, 158, 120, 1) !important;
          border-style: solid !important;
        }
      }
      &-btn {
        padding: 10px;
        width: 100%;
        border-radius: 5px;
        min-height: 60px;
        border-radius: 0;
        background: rgba(200, 158, 120, 1);
        color: rgba(255, 255, 255, 1);
        font-weight: bold;
        border: none;
        margin-top: 10px;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  &-image {
    min-width: 300px;
    min-height: 300px;
    canvas {
      width: 300px !important;
      height: 300px !important;
    }
    h2 {
      font-size: 16px;
      font-weight: normal;
      margin-top: 20px;
      margin-bottom: 20px;
      word-wrap: break-word;
      a {
        color: white;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  &-status {
    text-align: center;
    color: white;
    width: 100%;
  }

  .download-qr {
    margin: 0 auto;
    border: none;
    background: rgba(0, 0, 0, 0%);
    color: rgba(255, 255, 255);
    font-weight: bold;
    text-decoration: none;
    border-bottom: 2px solid rgba(200, 158, 120);
    padding: 10px 0;
    cursor: pointer;
  }
}
