.footer {
  small {
    color: rgba(36, 36, 36, 1);
    padding: 40px;
    text-align: CENTER;
    display: BLOCK;
    svg {
      fill: rgba(36, 36, 36, 1);
      margin: 0 5px;
    }
    a {
      color: rgba(36, 36, 36, 1);
      text-decoration: none;
    }
  }
}
